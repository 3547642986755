import en from "@/assets/locales/en.json";

export const locales = {
  en,
  ca: en,
  "ca-ES": en,
  cs: en,
  de: en,
  fr: en,
  it: en,
  nl: en,
  pl: en,
  tr: en,
  vi: en,
  zh: en,
  he: en,
  sv: en,
  pirate: en,
  cat: en,
  uwu: en,
  minion: en,
  lv: en,
  th: en,
  ne: en,
  ar: en,
  es: en,
  et: en,
  tok: en,
  hi: en,
  "pt-BR": en,
  "pt-PT": en,
  uk: en,
  bg: en,
  bn: en,
  el: en,
  fa: en,
  gu: en,
  id: en,
  ja: en,
  ko: en,
  sl: en,
  ta: en,
  "zh-Hant": en,
  is: en,
  ru: en,
  gl: en,
  pa: en,
  ro: en,
  fi: en,
  nv: en,
};

export type Locales = keyof typeof locales;
